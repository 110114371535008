import React, { Component } from "react";

import UserDataService from "../services/user.service";
import LocationDataService from "../services/location.service";
import CategoryDataService from "../services/category.service";

import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
 
import ZoomControl from '../components/zoom-control.component';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export default class Map extends Component {
  constructor(props) {
    super(props);
    this.getLocationData = this.getLocationData.bind(this);
    this.getPublishedLocations = this.getPublishedLocations.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.getBounds = this.getBounds.bind(this);
    this.formatGeoJSON = this.formatGeoJSON.bind(this);
    this.initMap = this.initMap.bind(this);

    this.state = {
      locations: [],
      currentLocation: null,
      lat: this.props.lat ||-37.81333017785542,
      lng: this.props.lng || 144.9614772172758,
      zoom: this.props.zoom || 9,
      map:null,
      categories:[],
      catColors:{},
      bounds:[[null,null],[null,null]] // bounds [xMin, yMin][xMax, yMax]
    };
    this.mapContainer = React.createRef();
  }
  

  componentDidMount() {
    this.getCategories();
    this.getLocationData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.latitude !== prevProps.latitude || this.props.longitude !== prevProps.longitude || this.props.id !== prevProps.id) {
      this.setState({
        lat: this.props.lat ||-37.81333017785542,
        lng: this.props.lng || 144.9614772172758,
        zoom: this.props.zoom || 9
      });
      this.getLocationData();
    }
  }

  componentWillUnmount() {
    if (this.state.map !== null) {
      this.state.map.remove();
    }
    
  }


  getLocationData () {
    if (this.props.id) {
      this.getLocation(this.props.id);
    } else {
      this.getPublishedLocations();
    }
    
  }

  getPublishedLocations() {
    LocationDataService.getPublished()
      .then(response => {
        console.log(response.data);
        this.setState({
          locations: this.formatGeoJSON(response.data)
        });
        this.setState({
          bounds : this.getBounds(response.data)
        });
        this.initMap();
      })
      .catch(e => {
        console.log(e);
      });
  }

  getLocation(id) {
    LocationDataService.get(id)
      .then(response => {
        console.log("response.data");
        console.log(response.data);
        this.setState({
          locations: this.formatGeoJSON([response.data])
        });
        this.initMap();
      })
      .catch(e => {
        console.log(e);
      });
  }

  getCategories(cb) {
    CategoryDataService.getAll()
      .then(response => {
        console.log(response.data);
        var catColors = {};
        response.data.forEach((category) => {
          catColors[category.id.toString()] = category.color;
        });
        this.setState({
          categories: response.data,
          catColors : catColors
        });
        console.log('state');
        console.log(this.state);
        
        
        typeof cb === 'function' && cb();
      })
      .catch(e => {
        console.log(e);
      });

    
  }

  getBounds(locations) {
    var bounds = [[9999,9999],[-9999,-9999]]; // bounds [xMin, yMin][xMax, yMax]

    locations.map((location,index) => {
      var longitude = location.longitude;
      var latitude = location.latitude;
      bounds[0][0] = bounds[0][0] < longitude ? bounds[0][0] : longitude;
      bounds[1][0] = bounds[1][0] > longitude ? bounds[1][0] : longitude;
      bounds[0][1] = bounds[0][1] < latitude ? bounds[0][1] : latitude;
      bounds[1][1] = bounds[1][1] > latitude ? bounds[1][1] : latitude;
    });

    return bounds;

  }



  initMap() {

    if (this.state.map !== null) {
      this.state.map.remove();
    }
    const { lng, lat, zoom } = this.state;
    const map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: 'mapbox://styles/melmo/ckotwhxhj670j17p61zhj5x3p',
      //style: 'mapbox://styles/mapbox/light-v9',
      center: [lng, lat],
      zoom: zoom
    });

    this.setState({
      map : map
    });

    map.on('move', () => {
      this.setState({
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        zoom: map.getZoom().toFixed(2)
      });
    });

    
    map.on('load', () => {
      map.addSource('locations', {
        type: 'geojson',
        data: this.state.locations
      });

      // Add a layer showing the places.
      map.addLayer({
        'id': 'locations',
        'type': 'symbol',
        'source': 'locations',
        'layout': {
        'text-line-height': 1, // this is to avoid any padding around the "icon"
       'text-padding': 0,
       'text-anchor': 'center', // center, so when rotating the map, the "icon" stay on the same location
       'text-offset': [0, -0.3], // give it a little offset on y, so when zooming it stay on the right place
       'text-allow-overlap': true,
       'text-ignore-placement': true,
          'text-field' : String.fromCharCode(0xf041),
          'text-font' : ['Font Awesome 5 Free Solid'],
          'text-size': 15,
          'icon-optional': true,
          //'icon-image': 'marker-15',
          'icon-allow-overlap': true,
          'icon-size':1,

        },
        paint: {
          "text-color": [
            'get', ['get','category'], ['literal', this.state.catColors]
             
            ]
        }
      });

      
    });

    if (!this.props.id) {

      // fit map to markers
      map.fitBounds(this.state.bounds, {
        padding : {
          top : 50,
          right : 250,
          bottom : 50,
          left : 50,
        }
      });

          // When a click event occurs on a feature in the places layer, open a popup at the
  // location of the feature, with description HTML from its properties.
      map.on('click', 'locations', function (e) {
        var coordinates = e.features[0].geometry.coordinates.slice();
        var description = e.features[0].properties.description;
         
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
       
      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(map);
      });
       
      // Change the cursor to a pointer when the mouse is over the places layer.
      
      map.on('mouseenter', 'locations', function () {
        map.getCanvas().style.cursor = 'pointer';
      });
       
      // Change it back to a pointer when it leaves.
      map.on('mouseleave', 'locations', function () {
        map.getCanvas().style.cursor = '';
      });

    }



    
 

  }

  formatGeoJSON(locations) {
    
    var obj = locations.map(function(location, index) {
      if (location.images && location.lead_image) {
        var image = location.images.find(image => {
          return image.id == location.lead_image;
        });
      }
      
      var description = '<a class="popup" target="_blank" href="'+process.env.REACT_APP_DOMAIN +'/locations/'
            + location.slug 
            + '"><h4>'
            + location.title 
            + '</h4>';
      if (image) {
        description += '<img src="'
            + image.url
            + '"/>';
      }
      description += '<div>' + location.description + '</div></a>';
      return {
        'type': 'Feature',
        'properties': {
          'description': description ,
          'category' : location.categoryId ? location.categoryId.toString() : '0',
          'color' : ''
        },
        'geometry': {
          'type': 'Point',
          'coordinates': [location.longitude,location.latitude]
        }
      }
    });

    var geoLocations = {
      "type": "FeatureCollection",
      "features": obj
    }
    console.log(obj);
    return geoLocations;

  }


  render() {
    return (
      <div className="">
        <div id="map" ref={this.mapContainer} className="map-container"></div>
        <ZoomControl map={this.state.map}/>
        {!this.props.id && this.state.categories &&  (
          <div id="legend">
          <h5>Categories</h5>
          {this.state.categories.map(category => (

          <p key={category.id}>
          <span className="color-block" style={{backgroundColor : category.color}}></span>
          {category.name}
          </p>
          
          ))}
          </div>
        )}
      </div>
    );
  }
}