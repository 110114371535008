import React, { Component } from "react";
import { useEditor, EditorContent,Editor } from '@tiptap/react'
import { defaultExtensions } from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'

export default class TiptapSimple extends Component {
  constructor(props) {
  	super(props);

    this.setLink = this.setLink.bind(this);
    console.log(Link);
    Link.options.openOnClick = false;

  	this.state = {
  		editor : new Editor({
		  content: {
		    "type": "doc",
		    "content": [
		      // …
		    ]
		  },
		  extensions: [
		      ...defaultExtensions(),
          Link
		    ],
		  
		})
  	
  	};

  	this.state.editor.on('update',({editor}) =>{
  		this.props.onChange(this.state.editor.getHTML());
  	});
  }

  

  componentDidMount() {
  	this.state.editor.commands.setContent(this.props.content);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  	if (this.props.content !== prevProps.content && this.props.content !== this.state.editor.getHTML()) {
  		this.state.editor.commands.setContent(this.props.content);
  	}

  	
  }

 setLink() {
  console.log('set link');
    const url = window.prompt('URL');
    console.log(this.state);
    this.state.editor.chain().focus().setLink({ href: url, target:"_blank" }).run();
  }


  render () {
  	return (
	    <div>
	      <MenuBar editor={this.state.editor} setLink={this.setLink}/>
	      <EditorContent editor={this.state.editor} />
	    </div>
	  )
  }
  
}





const MenuBar = ({ editor, setLink }) => {
  if (!editor) {
    return null
  }

  return (
    <div className="prosemirror-menu btn-group">
      <button
      	type="button"
        title="Bold"
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'btn btn-secondary' : 'btn  btn-secondary'}
      >
        <img src="/icons/bold.svg"/>
        <span className="sr-only">bold</span>
      </button>
      <button
      	type="button"
        title="Italic"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'btn btn-secondary' : 'btn  btn-secondary'}
      >
        <img src="/icons/italic.svg"/>
        <span className="sr-only">italic</span>
      </button>
      <button
      	type="button"
        title="Strikethrough"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={editor.isActive('strike') ? 'btn btn-secondary' : 'btn  btn-secondary'}
      >
        <img src="/icons/strike.svg"/>
        <span className="sr-only">strike</span>
      </button>
      
      
      <button 
      type="button"
      title="Add link"
        onClick={() => setLink()} 
        className={editor.isActive('link') ? 'btn btn-secondary' : 'btn  btn-secondary'}>
        <img src="/icons/link.svg"/>
        <span className="sr-only">Add link</span>
        
      </button>
      <button 
      type="button"
      title="Remove link"
        onClick={() => editor.chain().focus().unsetLink().run()} 
        className={editor.isActive('link') ? 'btn btn-secondary' : 'btn  btn-secondary'}>
        <img src="/icons/unlink.svg"/>
        <span className="sr-only">Remove link</span>
      </button>

      
      
      <button
      	type="button"
        title="Heading"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive('heading', { level: 3 }) ? 'btn btn-secondary' : 'btn  btn-secondary'}
      >
        H
        <span className="sr-only">heading</span>
      </button>
      
      <button
      	type="button"
        title="Bullet list"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'btn btn-secondary' : 'btn  btn-secondary'}
      >
        <img src="/icons/ul.svg"/>
        <span className="sr-only">bullet list</span>
      </button>
      <button
      title="Numbered list"
      	type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'btn btn-secondary' : 'btn  btn-secondary'}
      >
        <img src="/icons/ol.svg"/>
        <span className="sr-only">numbered list</span>
      </button>
      
      
      
      <button 
      	type="button"
        title="Undo"
        className="btn btn-secondary"
      	onClick={() => editor.chain().focus().undo().run()}>
        <img src="/icons/undo.svg"/>
        <span className="sr-only">undo</span>
      </button>
      <button 
      	type="button"
        title="Redo"
        className="btn btn-secondary"
      	onClick={() => editor.chain().focus().redo().run()}>
        <img src="/icons/redo.svg"/>
        <span className="sr-only">redo</span>
      </button>

      <button 
        type="button" 

        title="Clear formatting"
        className="btn btn-secondary"
        onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}>
        <img src="/icons/remove.svg"/>
        <span className="sr-only">clear formatting</span>
      </button>
    </div>
  )
}
  