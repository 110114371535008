import React, { Component } from "react";
import LocationDataService from "../services/location.service";
import UserDataService from "../services/user.service";
import ImageDataService from "../services/image.service";
import ResidentDataService from "../services/resident.service";
import ResourceDataService from "../services/resource.service";
import OptionsDataService from "../services/options.service";

import AuthService from "../services/auth.service";
import Map from "../components/map.component";
import YoutubeEmbed from "../components/youtube.component";
import Slider from "react-slick";


export default class Location extends Component {
  constructor(props) {
    super(props);
    
    this.getLocation = this.getLocation.bind(this);
    this.getImages = this.getImages.bind(this);
    this.getResidents = this.getResidents.bind(this);
    this.getResources = this.getResources.bind(this);
    this.getOptions = this.getOptions.bind(this);
    
    

    this.state = {
      currentLocation: {
        id: null,
        title: "",
        description: "",
        published: false,
        authorId:null,
        latitude:null,
        longitude:null,
        history:'',
        introduction:'',
        youtube_id : '',
        call_to_action_text :'',
        call_to_action_link : '',
        call_to_action_button : ''
      },
      images : [],
      residents : [],
      resources :[],
      options : {}
    };
  }

  componentDidMount() {
    this.getLocation(this.props.match.params.slug);
    this.getOptions();
  }


  getLocation(slug) {
    console.log(slug);
    LocationDataService.findBySlug(slug)
      .then(response => {
        console.log("response.data");
        console.log(response.data);

        this.setState({
          currentLocation: response.data
        });
        if (this.state.currentLocation !== null) {
          this.getImages(this.state.currentLocation.id);
          this.getResidents(this.state.currentLocation.id);
          this.getResources(this.state.currentLocation.id);
        }
        
        
      })
      .catch(e => {
        console.log("e.data");
        console.log(e);
      });
  }

  

  getImages(id) {
    ImageDataService.findByLocation(id)
      .then(response => {
        this.setState({
          images : response.data
        })
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  getResidents(id) {

    ResidentDataService.findByLocation(id)
      .then(response => {
        this.setState({
          residents : response.data
        })
      })
      .catch(e => {
        console.log(e);
      });

  }

  getResources(id) {
    ResourceDataService.findByLocation(id)
      .then(response => {
        this.setState({
          resources : response.data
        })
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  getOptions() {
    OptionsDataService.get(1)
      .then(response => {
        console.log("options");
        console.log(response.data);
        this.setState({
          options: response.data
        });
      })
      .catch(e => {
        console.log(e);
      });

  }
  

  render() {
    const { currentLocation, images, residents, resources } = this.state;

    return (
      <div className="location-view">
        {currentLocation  ? (
          <div className="">
            
            <h1>{currentLocation.title}</h1>
            <div className="introduction">{currentLocation.introduction}</div>
            
            
            <div id="images">
                <Slider {...{
                  dots: true,
                  infinite: true,
                  speed: 500,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay:true,
                  arrows:false
                }}>
                {images.map(image => (

                  <div key={image.id}>
                  <img src={image.url}/>
                    
                  </div>

                  ))}
                </Slider>
                </div>

              
            <div className="row">
              <div className="col-12">
              
              </div>
              <div className="col-md-8">
                {currentLocation.history && currentLocation.history !== '' && (
                  <div dangerouslySetInnerHTML={{
                  __html: currentLocation.history
                }}></div>
                )}
                {currentLocation.youtube_id && currentLocation.youtube_id !== '' && (
                <div id="youtube">
                  <YoutubeEmbed embedId={currentLocation.youtube_id} />
                </div>
                )}
              </div>
              <div className="col-md-4">
              {currentLocation.id && (
                <div className="map-wrap-small">
                  <Map
                    zoom="13"
                    lat={currentLocation.latitude}
                    lng={currentLocation.longitude}
                    id={currentLocation.id}
                  />
                  </div>
              )}
              </div>
              </div>
              <div className="row">
                <div className="col-sm-8 offset-sm-2">

                  {currentLocation.call_to_action_link && (
                    <div className="call-to-action">
                      {currentLocation.call_to_action_text && (
                        <p>{currentLocation.call_to_action_text}</p>
                      )}
                      <a target="_blank" className="btn btn-lg btn-action" href={currentLocation.call_to_action_link}>{currentLocation.call_to_action_button || 'Take action now'}</a>
                    </div>

                  )}
                  {!currentLocation.call_to_action_link && this.state.options.call_to_action_link && (
                    <div className="call-to-action">
                      {this.state.options.call_to_action_text && (
                        <p>{this.state.options.call_to_action_text}</p>
                      )}
                      <a target="_blank" className="btn btn-lg btn-action" href={this.state.options.call_to_action_link}>{this.state.options.call_to_action_button || 'Take action now'}</a>
                    </div>

                  )}
                </div>
              </div>
              <div className="row">
              
              {residents.length > 0 && (
              <div  id="residents" className="col-12">
                <h2>Resident Stories</h2>
              <div>
              <div className="col-sm-8 offset-sm-2">
                <Slider settings = {{
                  dots: true,
                  infinite: true,
                  speed: 500,
                  slidesToShow: 1,
                  slidesToScroll: 1
                }}>
                {residents.map(resident => (

                  <div className="card" key={resident.id}>
                  <div className="card-body">

                  <h5 className="card-title">{resident.name}</h5>
                    
                    <div>{resident.story}</div>
                  </div>
                  </div>

                  ))}
                </Slider>
                </div>
                </div>
              </div>
              )}
              {resources.length > 0 && (
              <div className="col-12">
              
              <h2>Further resources</h2>
              <ul id="resources">
                {resources.map(resource => (
                  <li key={resource.id}>
                    <a target="_blank" href={resource.url}>{resource.name}</a><br/>
                    <p>{resource.description}</p>
                  </li>

                  ))}
                </ul>
              </div>
              )}
              
            </div>


          </div>
        ) : (
          <div>
            <br />
            <p>No location found</p>
          </div>
        )}
      </div>
    );
  }
}