import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";


export default function PrivateRoute ({component: Component, auth, authCheckComplete, ...rest}) {
	console.log("auth: " + auth);

  return (
    <Route
      {...rest}
      render={(props) => authCheckComplete === false
      	? <div></div>
      	: auth === true
        	? <Component {...props} />
        	: <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}



/*

export default class PrivateRoute extends Component {

	constructor(props) {
	    super(props);
	}

	render() {
		return(
			<Route
		      {...this.rest}
		      render={(props) => this.auth === false
		        ? <Component {...this.props} />
		        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
		    />

		);
	}

}

*/