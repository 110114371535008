import React, { Component } from "react";
import ResidentDataService from "../services/resident.service";
import axios from 'axios';


export default class ImageLibrary extends Component {
  constructor(props) {
  	super(props);
  	this.getResidents = this.getResidents.bind(this);
  	this.onChangeName = this.onChangeName.bind(this);
  	this.onChangeStory = this.onChangeStory.bind(this);
  	this.onRemove = this.onRemove.bind(this);
  	this.onUpdate = this.onUpdate.bind(this);
  	this.onChangeNewName = this.onChangeNewName.bind(this);
  	this.onChangeNewStory = this.onChangeNewStory.bind(this);
  	this.onUpload = this.onUpload.bind(this);
  	this.state = {
  		residents : [],
  		newResident : {
  			name : '',
  			story : ''
  		}
  	}
  }

  componentDidMount() {
  	this.getResidents(this.props.location);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  	if(this.props.location !== prevProps.location) {
  		this.getResidents(this.props.location);
  	}
  	
  }

  getResidents(location, cb) {
  	if (location) {
  		ResidentDataService.findByLocation(location)
  		  .then(response => {
	        this.setState({
	          residents: response.data
	        });
	        
	        console.log(response.data);
          typeof cb === 'function' && cb();
	      })
	      .catch(e => {
	        console.log(e);
	      });

  	}
  }

  onChangeName(id, e) {
  	
	const newResidents = this.state.residents.map((resident) => {
      if (resident.id === id) {
        const updatedResident = {
          ...resident,
          name: e.target.value,
        };
 
        return updatedResident;
      }
 
      return resident;
    });
	this.setState({
		residents : newResidents
	});
  }

  onChangeStory(id, e) {
  	
	const newResidents = this.state.residents.map((resident) => {
      if (resident.id === id) {
        const updatedResident = {
          ...resident,
          story: e.target.value,
        };
 
        return updatedResident;
      }
 
      return resident;
    });
	this.setState({
		residents : newResidents
	});
  }

  onRemove(id) {
  	if (window.confirm("Are you sure you want to delete this story?")) {
  		ResidentDataService.delete(id)
	  	.then(response => {
	  		this.getResidents(this.props.location);
	  	})
	  	.catch(e => {
	  		console.log(e);
	  	});
  	}
  	
  }

  onUpdate(id) {
  	const resident = this.state.residents.find(resident => {
	  return resident.id === id
	});
  	
  	ResidentDataService.update(id, resident)
	  	.then(response => {
	  		this.getResidents(this.props.location, () => {
            const newResidents = this.state.residents.map((resident) => {
                if (resident.id === id) {
                  const updatedResident = {
                    ...resident,
                    status:"Updated successfully",
                  };
           
                  return updatedResident;
                }
           
                return resident;
              });
            this.setState({
              residents : newResidents
            });
        });
	  	})
	  	.catch(e => {
	  		console.log(e);
        const newResidents = this.state.residents.map((resident) => {
            if (resident.id === id) {
              const updatedResident = {
                ...resident,
                status:"Update unsuccessful",
              };
       
              return updatedResident;
            }
       
            return resident;
          });
        this.setState({
          residents : newResidents
        });
	  	});

  }

  onChangeNewName(e) {
  	this.setState(prevState => ({
       newResident: {
        ...prevState.newResident,
        name: e.target.value
      }
    }));
  }

  onChangeNewStory(e) {
  	this.setState(prevState => ({
       newResident: {
        ...prevState.newResident,
        story: e.target.value
      }
    }));
  }


  onUpload() {

  	var data = {
  		'name' : this.state.newResident.name,
  		'story' : this.state.newResident.story,
  		'locationId' : this.props.location
  	};

  	ResidentDataService.create(data)
      .then(response => {
        this.setState({
          newResident : {
  			name : '',
  			story : ''
  		  }
        });
        this.getResidents(this.props.location);
      })
      .catch(e => {
        console.log(e);
      });

  }


  render () {
  	return (
  		<div className="location-library">
      <h2>Resident Stories</h2>
  		<ul>
		      {this.state.residents && this.state.residents.length > 0 && this.state.residents.map(resident => (
		      	<li  key={resident.id}>
            <div className="card">
            <div className="card-body">
			      	<label htmlFor={"name-" + resident.id}>Name</label>
			    	<input
				    	type="text"
		                className="form-control"
		                id={"name-" + resident.id}
		                value={resident.name}
		                onChange={(e) => this.onChangeName(resident.id,e)}
		                />
		            <label htmlFor={"story-" + resident.id}>Story</label>
			    	<textarea
			    		
		                className="form-control paragraphs"
		                id={"story-" + resident.id}
		                value={resident.story}
		                onChange={(e) => this.onChangeStory(resident.id,e)}
		                />
                    </div>
                    <div className="card-footer">

		            <button 
              type="button" 
              onClick={() => this.onUpdate(resident.id)}
              className="btn btn-success"
              >
                Update
              </button>
              <button 
              type="button" 
              onClick={() => this.onRemove(resident.id)}
              className="btn btn-danger"
              >
                Remove
              </button>
              <div className="status">
                {resident.status}
              </div>
              </div>
              </div>
		      	</li>
		      ))}
		    </ul>
		    <div className="card">
			    
          <div className="card-body">
          <h4 className="card-title">Add new resident story</h4>
			    <label htmlFor="new-name">Name</label>
			    	
			    	<input
				    	type="text"
		                  className="form-control"
		                  id="new-name"
		                  value={this.state.newResident.name}
		                  onChange={this.onChangeNewName}
		                  />

		                  <label htmlFor="new-story">Story</label>
			    	<textarea
	                  className="form-control paragraphs"
	                  id="new-story"
	                  value={this.state.newResident.story}
	                  onChange={this.onChangeNewStory}
	                  />

	                  <button
	                  	type="button"
	                  	value="Upload"
	                  	onClick={this.onUpload}
                      className="btn btn-primary"
	                  	>Upload</button>

            </div>
		    </div>
  		</div>
	)
  }
}