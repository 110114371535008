import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";

import "./App.scss";

import AddLocation from "./components/add-location.component";
import EditLocation from "./components/edit-location.component";
import LocationsList from "./components/locations-list.component";
import Location from "./components/location.component";

import AddUser from "./components/add-user.component";
import User from "./components/user.component";
import UsersList from "./components/users-list.component";

import AuthService from "./services/auth.service";

import PrivateRoute from "./components/private-route.component";
import Login from "./components/login.component";
import ResetPassword from "./components/reset-password.component";
import RequestToken from "./components/request-token.component";
import Home from "./components/home.component";
import Map from "./components/map.component";
import Profile from "./components/profile.component";
import CategoryList from "./components/edit-category.component";
import OptionsEditor from "./components/edit-options.component";

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import OptionsDataService from "./services/options.service";



class App extends Component {

  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.getOptions = this.getOptions.bind(this);

    this.state = {
      authCheckComplete:false,
      isLoggedIn:false,
      isMod: false,
      isAdmin: false,
      currentUser: undefined,
      options: {}
    };

    
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    
    if (user) {
      this.setState({
        currentUser: user,
        isLoggedIn:user?true:false,
        isMod: user.roles.includes("ROLE_MODERATOR"),
        isAdmin: user.roles.includes("ROLE_ADMIN"),
      });
    }
    this.setState({
      authCheckComplete : true
    });

    this.getOptions();
  }

  getOptions() {
    OptionsDataService.get(1)
      .then(response => {
        this.setState({
          options: response.data
        });
      })
      .catch(e => {
        console.log(e);
      });

  }

  logOut() {
    AuthService.logout();
  }
  render() {

    const { currentUser, isLoggedIn, isMod, isAdmin, authCheckComplete } = this.state;
    return (
      <div>
        <nav id="nav" className="navbar navbar-expand navbar-light bg-dark">
        <div className="d-md-flex justify-content-between">
          

          <div className="flex-item  navbar-nav order-md-1">

            {currentUser && (
              <DropdownButton title="Manage" menuAlign="left" id="manage-menu">
                
                  <Dropdown.Item as="button">
                    <Link to={"/admin/locations"} className="nav-link">
                      Locations
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="button">
                    <Link to={"/admin/locations/add"} className="nav-link">
                      Add New Location
                    </Link>
                  </Dropdown.Item>
                  {isAdmin && (
                <div>
                  <Dropdown.Item as="button">
                    <Link to={"/admin/users"} className="nav-link">
                      Users
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="button">
                    <Link to={"/admin/users/add"} className="nav-link">
                      Add New User
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="button">
                    <Link to={"/admin/categories"} className="nav-link">
                      Categories
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="button">
                    <Link to={"/admin/options"} className="nav-link">
                      Options
                    </Link>
                  </Dropdown.Item>
                  
                
                </div>
              )}
                  <Dropdown.Item as="button">
                    <Link to={"/profile"} className="nav-link">
                      My profile
                    </Link>
                  </Dropdown.Item>
              </DropdownButton>
            )}

            
              

              

              
            
            {currentUser ? (
                
                <div className="nav-item">
                  <a href="/login" className="btn btn-action" onClick={this.logOut}>
                    Log out
                  </a>
              </div>
            ) : (
                <div className="nav-item d-none d-md-block">
                  <Link to={"/login"} className="btn btn-action">
                    Log in
                  </Link>

                
              </div>
            )}
          </div>

          <div className="flex-item  logo order-md-0">
            <a href="/" className="navbar-brand">
              <img id="logo" src="/img/sphc-logo.png"/>
            </a>
            <a href="/" className="navbar-brand navbar-brand-text">
              <span className="small">Save Public Housing Collective</span><br/>
              Public Housing Watch Victoria
            </a>
          </div>

          </div>

        </nav>

        <div id="main" className="container mt-3">
          
            <Switch>
              <Route exact path={["/", "/home"]} component={Home} />

              <Route exact path={["/map"]} component={Map} />

              <PrivateRoute auth={isLoggedIn} authCheckComplete={authCheckComplete} exact path={["/admin/locations"]} component={LocationsList} />
              <PrivateRoute auth={isLoggedIn} authCheckComplete={authCheckComplete} path={["/admin/locations/add"]} component={AddLocation} />
              <PrivateRoute auth={isLoggedIn} authCheckComplete={authCheckComplete} path={["/admin/locations/:id"]} component={EditLocation} />

              <Route path={["/locations/:slug"]} component={Location} />

              <PrivateRoute auth={isAdmin} authCheckComplete={authCheckComplete} exact path={["/admin/users"]} component={UsersList} />
              <PrivateRoute auth={isAdmin} authCheckComplete={authCheckComplete} path={["/admin/users/add"]} component={AddUser} />
              <PrivateRoute auth={isAdmin} authCheckComplete={authCheckComplete} path={["/admin/users/:id"]} component={User} />
              <PrivateRoute auth={isAdmin} authCheckComplete={authCheckComplete} path={["/admin/categories"]} component={CategoryList} />
              <PrivateRoute auth={isAdmin} authCheckComplete={authCheckComplete} path={["/admin/options"]} component={OptionsEditor} />

              <PrivateRoute auth={isLoggedIn} authCheckComplete={authCheckComplete} exact path={["/profile"]} component={Profile} />
                                
              <Route exact path="/login" component={Login} />
              <Route exact path="/reset-password" component={RequestToken} />
              <Route path="/reset-password/:id/:token" component={ResetPassword} />

            </Switch>
        
        </div>
        <div id="footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-7">
              {this.state.options && this.state.options.footer_text_1 && (
                <div dangerouslySetInnerHTML={{
                  __html: this.state.options.footer_text_1
                }}></div>

                )}
             
{!currentUser && (
                
                
                <div className="d-block d-md-none">
                  <Link to={"/login"} className="btn btn-action">
                    Log in
                  </Link>

                
              </div>
            )}
</div>
<div className="col-sm-4 offset-sm-1">
{this.state.options && this.state.options.footer_text_2 && (
                <div dangerouslySetInnerHTML={{
                  __html: this.state.options.footer_text_2
                }}></div>

                )}
              
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

 
}

export default App;