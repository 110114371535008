import React, { Component } from "react";
import LocationDataService from "../services/location.service";
import UserDataService from "../services/user.service";
import AuthService from "../services/auth.service";
import CategoryDataService from "../services/category.service";
import TiptapSimple from '../components/tiptap-simple.component';
import ImageLibrary from '../components/image-library.component';
import ResidentLibrary from '../components/resident-library.component';
import ResourceLibrary from '../components/resource-library.component';


export default class Location extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeSlug = this.onChangeSlug.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeIntroduction = this.onChangeIntroduction.bind(this);
    this.onChangeHistory = this.onChangeHistory.bind(this);
    this.onChangeYoutube = this.onChangeYoutube.bind(this);
    this.onChangeCallToActionText = this.onChangeCallToActionText.bind(this);
    this.onChangeCallToActionLink = this.onChangeCallToActionLink.bind(this);
    this.onChangeCallToActionButton = this.onChangeCallToActionButton.bind(this);
    this.onChangeAuthorId = this.onChangeAuthorId.bind(this);
    this.onChangeCategoryId = this.onChangeCategoryId.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.onChangeLatitude = this.onChangeLatitude.bind(this);
    this.onChangeLongitude = this.onChangeLongitude.bind(this);
    this.onSetLeadImage = this.onSetLeadImage.bind(this);
    this.getAuthor = this.getAuthor.bind(this);
    this.getAllAuthors = this.getAllAuthors.bind(this);
    this.getAllCategories = this.getAllCategories.bind(this);
    this.updatePublished = this.updatePublished.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.deleteLocation = this.deleteLocation.bind(this);

    this.state = {
      currentLocation: {
        id: null,
        title: "",
        slug:"",
        description: "",
        published: false,
        authorId:null,
        latitude:null,
        longitude:null,
        introduction:"",
        history:"",
        lead_image:null,
        categoryId : null,
        call_to_action_text : '',
        call_to_action_link : '',
        call_to_action_button : ''

      },
      message: "",
      messageClass:'',
      loggedInUser: null,
      isLoggedIn:false,
      isMod: false,
      isAdmin: false,
      allAuthors:[],
      allCategories:[]
    };
  }

  componentDidMount() {
    const loggedInUser = AuthService.getCurrentUser();

    if (loggedInUser) {
      this.setState({
        loggedInUser: loggedInUser.id,
        isLoggedIn:true,
        isMod: loggedInUser.roles.includes("ROLE_MODERATOR"),
        isAdmin: loggedInUser.roles.includes("ROLE_ADMIN"),
      });
    }
    this.getLocation(this.props.match.params.id);
    this.getAllCategories();
  }

  onChangeTitle(e) {
    const title = e.target.value;

    this.setState(function(prevState) {
      return {
        currentLocation: {
          ...prevState.currentLocation,
          title: title
        }
      };
    });
  }
  onChangeSlug(e) {
    const slug = e.target.value;

    this.setState(function(prevState) {
      return {
        currentLocation: {
          ...prevState.currentLocation,
          slug: slug
        }
      };
    });
  }

  onChangeDescription(e) {
    const description = e.target.value;
    
    this.setState(prevState => ({
      currentLocation: {
        ...prevState.currentLocation,
        description: description
      }
    }));
  }

  onChangeIntroduction(e) {
    const introduction = e.target.value;
    
    this.setState(prevState => ({
      currentLocation: {
        ...prevState.currentLocation,
        introduction: introduction
      }
    }));
  }

  onChangeYoutube(e) {
    const youtube_id = e.target.value;
    
    this.setState(prevState => ({
      currentLocation: {
        ...prevState.currentLocation,
        youtube_id: youtube_id
      }
    }));
  }

  onChangeCallToActionText(e) {
    const call_to_action_text = e.target.value;
    
    this.setState(prevState => ({
      currentLocation: {
        ...prevState.currentLocation,
        call_to_action_text: call_to_action_text
      }
    }));
  }

  onChangeCallToActionLink(e) {
    const call_to_action_link = e.target.value;
    
    this.setState(prevState => ({
      currentLocation: {
        ...prevState.currentLocation,
        call_to_action_link: call_to_action_link
      }
    }));
  }

  onChangeCallToActionButton(e) {
    const call_to_action_button = e.target.value;
    
    this.setState(prevState => ({
      currentLocation: {
        ...prevState.currentLocation,
        call_to_action_button: call_to_action_button
      }
    }));
  }

  onChangeHistory(history) {
    this.setState(prevState => ({
      currentLocation: {
        ...prevState.currentLocation,
        history: history
      }
    }));
  }

  onChangeAuthorId(e) {
    const authorId = e.target.value;
    
    this.setState(prevState => ({
      currentLocation: {
        ...prevState.currentLocation,
        authorId: authorId
      }
    }));
  }

  onChangeCategoryId(e) {
    const categoryId = e.target.value;
    
    this.setState(prevState => ({
      currentLocation: {
        ...prevState.currentLocation,
        categoryId: categoryId
      }
    }));
  }

  onChangeLatitude(e) {
    const latitude = e.target.value;
    
    this.setState(prevState => ({
      currentLocation: {
        ...prevState.currentLocation,
        latitude: latitude
      }
    }));
  }

  onChangeLongitude(e) {
    const longitude = e.target.value;
    
    this.setState(prevState => ({
      currentLocation: {
        ...prevState.currentLocation,
        longitude: longitude
      }
    }));
  }

  onSetLeadImage(id) {
    console.log("lead iamge");
    console.log(id);
    const state = this.state;
    this.setState(state => ({
      currentLocation: {
        ...state.currentLocation,
        lead_image: id
      }
    }), this.updateLocation);
    
    
  }

  getLocation(id) {
    LocationDataService.get(id)
      .then(response => {
        
        
        this.setState({    
          currentLocation: response.data
        });
        if (this.state.isAdmin) {
          this.getAuthor(response.data.authorId);
          this.getAllAuthors();
        }
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  getAuthor(id) {
    UserDataService.get(id)
      .then(response => {
        this.setState({

        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  getAllAuthors() {
    UserDataService.getAll()
      .then(response => {
        this.setState({
          allAuthors:response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  getAllCategories() {
    CategoryDataService.getAll()
      .then(response => {
        this.setState({
          allCategories:response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  updatePublished(status) {
    var data = this.prepareDataForUpdate(status);

    LocationDataService.update(this.state.currentLocation.id, data)
      .then(response => {
        this.setState(prevState => ({
          currentLocation: {
            ...prevState.currentLocation,
            published: status
          },
          message : response.data.message,
          messageClass : 'success'
        }));
        this.getLocation(this.state.currentLocation.id);
      })
      .catch(e => {
        console.log(e);
        this.setState({
          message: e,
          messageClass:'danger'
        });
        this.getLocation(this.state.currentLocation.id);
      });
  }

  updateLocation() {


    var data = this.prepareDataForUpdate(this.state.currentLocation.published);

    

    LocationDataService.update(
      this.state.currentLocation.id,
      data
    )
      .then(response => {
        this.setState({
          message: response.data.message,
          messageClass:'success'
        });
        this.getLocation(this.state.currentLocation.id);
      })
      .catch(e => {
        console.log(e);
        this.setState({
          message: e,
          messageClass:'danger'
        });
        this.getLocation(this.state.currentLocation.id);
      });
  }

  prepareDataForUpdate(status) {

    this.setState({
      message: '',
      messageClass : 'loading'
    });

    
    var data = {
      id: this.state.currentLocation.id,
      title: this.state.currentLocation.title,
      description: this.state.currentLocation.description,
      introduction: this.state.currentLocation.introduction,
      history : this.state.currentLocation.history == null ? '' : this.state.currentLocation.history,
      slug:this.state.currentLocation.slug,
      lead_image:this.state.currentLocation.lead_image,
      published: status,
      authorId:this.state.currentLocation.authorId,
      loggedInUser:this.state.loggedInUser,
      longitude:this.state.currentLocation.longitude,
      latitude:this.state.currentLocation.latitude,
      youtube_id:this.state.currentLocation.youtube_id,
      categoryId:this.state.currentLocation.categoryId,
      call_to_action_text : this.state.currentLocation.call_to_action_text,
      call_to_action_link : this.state.currentLocation.call_to_action_link,
      call_to_action_button : this.state.currentLocation.call_to_action_button
    };
    return data;
  }

  deleteLocation() {  
    if (window.confirm("Are you sure you want to delete this location?")) {
      LocationDataService.delete(this.state.currentLocation.id)
        .then(response => {
          console.log(response.data);
          this.props.history.push('/locations')
        })
        .catch(e => {
          console.log(e);
        });
    }  
    
  }

  render() {
    const { currentLocation } = this.state;

    return (
      <div>
        {currentLocation ? (
          <div className="edit-form edit-location">
          <div className="row">
            <div className="col-12">
              <h1>Edit Location</h1>

                {(currentLocation.slug) && ( 
              <p>View location: <a target="_blank" href={process.env.REACT_APP_DOMAIN + "/locations/" + currentLocation.slug}>
                {process.env.REACT_APP_DOMAIN + "/locations/" + currentLocation.slug}
                </a>
                </p>
                )}
            </div>
            <div className="col-sm-4 order-sm-2">

            <div className="location-controls">

              <div className="form-group">
                <label>
                  <strong>Status:</strong>
                </label>
                {currentLocation.published ? "Published" : "Pending"}
              </div>
              
              {(this.state.loggedInUser == currentLocation.authorId || this.state.isMod || this.state.isAdmin) && (
                  <button
                    className="btn btn-primary mr-2"
                    onClick={() => this.updatePublished(!currentLocation.published)}
                  >
                    {currentLocation.published ? ("Set as draft") : ("Publish") }
                  </button>
                 )} 

                  {(this.state.loggedInUser == currentLocation.authorId || this.state.isMod || this.state.isAdmin) && (
                  <button
                    type="submit"
                    className="btn btn-success mr-2"
                    onClick={this.updateLocation}
                  >
                    Update
                  </button>
                  )}



                  {this.state.isAdmin && (

                      <button
                        className="btn btn-danger"
                        onClick={this.deleteLocation}
                      >
                        Delete
                      </button>
                    )}

                  <p className={'alert alert-' + this.state.messageClass}>{this.state.message}</p>
                  {this.state.messageClass=='loading' && (
                    <div className="spinner-border text-secondary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-sm-8 order-sm-1">

              <form type="">
              <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="title">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    value={currentLocation.title}
                    onChange={this.onChangeTitle}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="title">URL</label>
                  <p className="small">https://map.savepublichousing.com/locations/</p>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    value={currentLocation.slug}
                    onChange={this.onChangeSlug}
                  />
                </div>

                {( ( this.state.isAdmin) &&  currentLocation.authorId) && (
                <div className="form-group">
                    <label htmlFor="authorId">Author</label>
                    <select className="form-control"
                      id="authorId"
                      required
                      value={currentLocation.authorId}
                      onChange={this.onChangeAuthorId}
                      name="authorId">

                      {
                        this.state.allAuthors &&
                        this.state.allAuthors.length != 0 &&
                        this.state.allAuthors.map((author,index) => (
                          <option value={author.id} key={author.id}>{author.username} ({author.email})</option>
                        ))
                      }
                      
                    </select>
                  </div>
                  ) }

                <div className="form-group">
                    <label htmlFor="categoryId">Category</label>
                    <select className="form-control"
                      id="categoryId"
                      required
                      value={currentLocation.categoryId || '0'}
                      onChange={this.onChangeCategoryId}
                      name="categoryId">
                      { currentLocation.categoryId === null &&(
                        <option value="0"></option>
                        )}

                      {
                        this.state.allCategories &&
                        this.state.allCategories.length != 0 &&
                        this.state.allCategories.map((category,index) => (
                          <option value={category.id} key={category.id}>{category.name}</option>
                        ))
                      }
                      
                    </select>
                  </div>

                <div className="row">
                  <div className="col">

                    <div className="form-group">
                      <label htmlFor="latitude">Latitude</label>
                      <input
                        type="text"
                        className="form-control"
                        id="latitude"
                        value={currentLocation.latitude || ""}
                        onChange={this.onChangeLatitude}
                      />
                    </div>
                  </div>
                  <div className="col">

                    <div className="form-group">
                      <label htmlFor="longitude">Longitude</label>
                      <input
                        type="text"
                        className="form-control"
                        id="longitude"
                        value={currentLocation.longitude || ""}
                        onChange={this.onChangeLongitude}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <p className="small">Describe the location in one sentence. This will be shown on the map pop ups.</p>
                  <textarea
                    type="text"
                    className="form-control"
                    id="description"
                    value={currentLocation.description}
                    onChange={this.onChangeDescription}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="introduction">Introduction</label>
                  <p className="small">One paragraph, emotive introduction to the location.</p>
                  <textarea
                    type="text"
                    className="form-control paragraphs"
                    id="introduction"
                    value={currentLocation.introduction || ""}
                    onChange={this.onChangeIntroduction}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="youtube_id">YouTube ID</label>
                  <p className="small">Enter the ID of the YouTube video. You can find the ID in the url. For example, for https://www.youtube.com/watch?v=Jn09UdSb3aA the ID is Jn09UdSb3aA</p>
                  <input
                    type="text"
                    className="form-control"
                    id="youtube_id"
                    value={currentLocation.youtube_id || ""}
                    onChange={this.onChangeYoutube}
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="history">History</label>
                  <p className="small">A detailed history of the location, and any other relevant information.</p>
                  
                  <TiptapSimple
                    data-lpignore="true"
                    id="history"
                    content={currentLocation.history || ""} 
                    onChange={this.onChangeHistory} 
                    />


                  
                </div>
                </div>
                </div>
                <div className="form-group">
                <div className="location-library">
                
                <h2>Call to action</h2>
                <div className="card">
                  <div className="card-body">
                  
                  
                  <label htmlFor="call_to_action_text">Text</label>
                  <p className="small">A short paragraph explaining the action, e.g. "Take five minutes to sign our petition." or "Write to the Minister for Housing at email@address.com."</p>
                  <textarea
                    
                    className="form-control"
                    id="call_to_action_text"
                    value={currentLocation.call_to_action_text || ""}
                    onChange={this.onChangeCallToActionText}
                  />
                  <label htmlFor="call_to_action_link">Link</label>
                  <p className="small">An optional link to another website, e.g. change.org.</p>
                  <input
                    type="url"
                    className="form-control"
                    id="call_to_action_link"
                    value={currentLocation.call_to_action_link || ""}
                    onChange={this.onChangeCallToActionLink}
                  />
                  <label htmlFor="call_to_action_button">Button text</label>
                  <p className="small">Optional. Defaults to "Take action now".</p>
                  <input
                    type="text"
                    className="form-control"
                    id="call_to_action_button"
                    value={currentLocation.call_to_action_button || ""}
                    onChange={this.onChangeCallToActionButton}
                  />
                </div>

                  </div>
                </div>
                </div>
                <div className="form-group">
                  
                  <ImageLibrary 
                    id="images"
                    location={currentLocation.id || ""} 
                    lead_image={currentLocation.lead_image}
                    onSetLeadImage={this.onSetLeadImage} 
                    />
                           


                  
                </div>

                <div className="form-group">
                  
                  <ResidentLibrary 
                    id="residents"
                    location={currentLocation.id || ""}
                    />
   
                </div>

                <div className="form-group">
                  
                  <ResourceLibrary 
                    id="resources"
                    location={currentLocation.id || ""}
                    />
   
                </div>
                
                

                
              </form>
            </div>
            
          </div>
            
            

            
            


            
          </div>
        ) : (
          <div>
            <br />
            <p>No location found with this id</p>
          </div>
        )}
      </div>
    );
  }
}