import React, { Component } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import ResourceDataService from "../services/resource.service";
import axios from 'axios';

export default class ResourceLibrary extends Component {
  constructor(props) {
  	super(props);
  	this.onRemove = this.onRemove.bind(this);
  	this.onUpdate = this.onUpdate.bind(this);
  	this.onUpload = this.onUpload.bind(this);
  	this.onChangeName = this.onChangeName.bind(this);
  	this.onChangeDescription = this.onChangeDescription.bind(this);
  	this.onChangeNewName = this.onChangeNewName.bind(this);
  	this.onChangeURL = this.onChangeURL.bind(this);
  	this.onChangeNewDescription = this.onChangeNewDescription.bind(this);
  	this.onChangeFile = this.onChangeFile.bind(this);
  	this.onToggleIsFile = this.onToggleIsFile.bind(this);
  	this.getResources = this.getResources.bind(this);
  	this.state = {
  		resources : [],
  		newResource : {
  			name : '',
  			description : '',
  			file : null,
  			url : '',
  			isFile : true
  		}
  	}
  }

  componentDidMount() {
  	this.getResources(this.props.location);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  	if(this.props.location !== prevProps.location) {
  		this.getResources(this.props.location);
  	}
  	
  }

  getResources(location, cb) {

  	if (location) {
  		ResourceDataService.findByLocation(location)
  		  .then(response => {
	        this.setState({
	          resources: response.data
	        });
	        
	        console.log(response.data);
          typeof cb === 'function' && cb();
	      })
	      .catch(e => {
	        console.log(e);
	      });

  	}
  }


  onToggleIsFile(e) {
  	this.setState(prevState => ({
       newResource: {
        ...prevState.newResource,
        isFile: e.target.checked,
        url : '',
        file : null
      }
    }));
  }

  onChangeURL(e) {
  	this.setState(prevState => ({
       newResource: {
        ...prevState.newResource,
        url: e.target.value
      }
    }));
  }

  onChangeNewName(e) {
  	this.setState(prevState => ({
       newResource: {
        ...prevState.newResource,
        name: e.target.value
      }
    }));
  }

  onChangeNewDescription(e) {
  	this.setState(prevState => ({
       newResource: {
        ...prevState.newResource,
        description: e.target.value
      }
    }));
  }

  onChangeFile(e) {
  	this.setState(prevState => ({
       newResource: {
        ...prevState.newResource,
        file: e.target.files[0]
      }
    }));
  }

  onChangeName(id, e) {
  	
	const newResources = this.state.resources.map((resource) => {
      if (resource.id === id) {
        const updatedResource = {
          ...resource,
          name: e.target.value,
        };
 
        return updatedResource;
      }
 
      return resource;
    });
	this.setState({
		resources : newResources
	});
  }

  onChangeDescription(id,e) {

  	const newResources = this.state.resources.map((resource) => {
      if (resource.id === id) {
        const updatedResource = {
          ...resource,
          description: e.target.value,
        };
 
        return updatedResource;
      }
 
      return resource;
    });
	this.setState({
		resources : newResources
	});

  }

  onUpload() {


  	const formData = new FormData();
  	formData.append('name' , this.state.newResource.name);
  	formData.append('description' , this.state.newResource.description);
  	formData.append('url' , this.state.newResource.url);
  	formData.append('locationId' , this.props.location);
  	formData.append('file' , this.state.newResource.file);


  	ResourceDataService.create(formData)
      .then(response => {
        this.setState({
          newResource : {
  			name : '',
  			description : '',
  			url : '',
  			file:null,
  			isFile:true
  		  }
        });
        document.getElementById("new-resource-file").value = "";
        this.getResources(this.props.location);
      })
      .catch(e => {
        console.log(e);
      });

  }

  onRemove(id) {
  	if (window.confirm("Are you sure you want to delete this resource?")) {
  		ResourceDataService.delete(id)
	  	.then(response => {
	  		this.getResources(this.props.location);
	  	})
	  	.catch(e => {
	  		console.log(e);
	  	});
  	}
  	
  }

  onUpdate(id) {
  	const resource = this.state.resources.find(resource => {
	  return resource.id === id
	});
  	
  	ResourceDataService.update(id, resource)
	  	.then(response => {
	  		this.getResources(this.props.location, () => {
          const newResources = this.state.resources.map((resource) => {
                if (resource.id === id) {
                  const updatedResource = {
                    ...resource,
                    status:"Updated successfully",
                  };
           
                  return updatedResource;
                }
           
                return resource;
              });
            this.setState({
              resources : newResources
            });
        });
	  	})
	  	.catch(e => {
	  		console.log(e);
        const newResources = this.state.resources.map((resource) => {
                if (resource.id === id) {
                  const updatedResource = {
                    ...resource,
                    status:"Update unsuccessful",
                  };
           
                  return updatedResource;
                }
           
                return resource;
              });
            this.setState({
              resources : newResources
            });
	  	});

  }

  render () {
  	return (
	    <div className="location-library">
      <h2>Resources</h2>
		    <ul>
		      {this.state.resources && this.state.resources.length > 0 && this.state.resources.map(resource => (
		        <li key={resource.id}>
            <div className="card">
            <div className="card-body">
		        	<p><a 
              href={resource.url} 
              target="_blank">
              {resource.url}
              </a></p>
		        	<label htmlFor={"name-" + resource.id}>Name</label>
			    	<input
				    	type="text"
		                  className="form-control"
		                  id={"name-" + resource.id}
		                  value={resource.name}
		                  onChange={(e) => this.onChangeName(resource.id,e)}
		                  />
		                  <label htmlFor={"description-" + resource.id}>Description</label>
			    	<textarea
		                  className="form-control paragraphs"
		                  id={"description-" + resource.id}
		                  value={resource.description}
		                  onChange={(e) => this.onChangeDescription(resource.id,e)}
		                  />
		          
              </div>
              <div className="card-footer">
              <button 
              type="button" 
              onClick={() => this.onUpdate(resource.id)}
              className="btn btn-success"
              >
                Update
              </button>
		          <button 
              type="button" 
              onClick={() => this.onRemove(resource.id)}
              className="btn btn-danger"
              >
		            Remove
		          </button>
              <div className="status">
                {resource.status}
              </div>
              </div>
              </div>
		          
		        </li>
		      ))}
		    </ul>
		    <div className="card">
		    
        <div className="card-body">
        <h4 className="card-title">Add new resource</h4>
        <span
        className={this.state.newResource.isFile ? 'file-toggle-label' : 'file-toggle-label file-toggle-label-active'}
        >URL</span>
		    <Toggle
			  id='is-file'
			  checked={this.state.newResource.isFile}
			  onChange={this.onToggleIsFile} />
        <span
        className={this.state.newResource.isFile ? ' file-toggle-label file-toggle-label-active' : 'file-toggle-label'}
        >File</span>
			<label htmlFor='is-file' className="sr-only">File or URL</label>

			{(this.state.newResource.isFile) && (

				<div className="form-group">

		    <label htmlFor="new-resource-file">File</label>
		    <input 
		    type="file" 
		    className="form-control"
		    id="new-resource-file" 
		    onChange={this.onChangeFile}
		     />
		     </div>
		     )} 

			{(!this.state.newResource.isFile) && (
				<div className="form-group">

		     <label htmlFor="new-url">URL</label>
		    <input 
		    type="url" 
		    className="form-control"
		    id="new-url" 
		    onChange={this.onChangeURL}
		     />
		     </div>
		      )}  

		    <label htmlFor="new-name">Name</label>
		    	
		    	<input
			    	type="text"
	                  className="form-control"
	                  id="new-name"
	                  value={this.state.newResource.name}
	                  onChange={this.onChangeNewName}
	                  />

	                  <label htmlFor="new-copyright">Description</label>
		    	<textarea
                  className="form-control paragraphs"
                  id="new-description"
                  value={this.state.newResource.description}
                  onChange={this.onChangeNewDescription}
                  />

                  <button
                  	type="button"
                  	value="Upload"
                  	onClick={this.onUpload}
                    className="btn btn-primary"
                  	>Upload</button>

            </div>
                  
		    </div>
	    </div>
	  )
  }
  
}