import React, { Component } from "react";
import UserDataService from "../services/user.service";
import AuthService from "../services/auth.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class User extends Component {
  constructor(props) {
    super(props);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeDoUpdatePassword = this.onChangeDoUpdatePassword.bind(this);
    this.onChangeRoleId = this.onChangeRoleId.bind(this);
    this.getUser = this.getUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);

    this.state = {
      currentUser: {
        id: null,
        username: "",
        email: "",
        roleId: 0,
        password:""
      },
      message: "",
      isLoggedIn:false,
      isAdmin:false,
      isMod:false,
      loggedInUser:0,
      newPassword : "",
      doUpdatePassword : false
    };
  }

  componentDidMount() {

    const loggedInUser = AuthService.getCurrentUser();

    if (loggedInUser) {
      this.setState({
        loggedInUser: loggedInUser.id,
        isLoggedIn:true,
        isMod: loggedInUser.roles.includes("ROLE_MODERATOR"),
        isAdmin: loggedInUser.roles.includes("ROLE_ADMIN"),
      });
    }

    if (this.props.match !== undefined) {
      this.getUser(this.props.match.params.id);
    } else {
      this.getUser(this.props.id);
    }
    console.log(this.state.currentUser);
  }

  onChangeDoUpdatePassword(e) {
    this.setState({
      doUpdatePassword : !this.state.doUpdatePassword
    });
  }

  onChangePassword(e) {
    const newPassword = e.target.value;

    this.setState(function(prevState) {
      return {
        currentUser: {
          ...prevState.currentUser,
          password: newPassword
        },
        newPassword:newPassword
      };
    });
  }

  onChangeUsername(e) {
    const username = e.target.value;

    this.setState(function(prevState) {
      return {
        currentUser: {
          ...prevState.currentUser,
          username: username
        }
      };
    });
  }

  onChangeEmail(e) {
    const email = e.target.value;
    
    this.setState(prevState => ({
      currentUser: {
        ...prevState.currentUser,
        email: email
      }
    }));
  }

  onChangeRoleId(e) {
    const roleId = e.target.value;
    
    this.setState(prevState => ({
      currentUser: {
        ...prevState.currentUser,
        roleId: roleId
      }
    }));
  }

  getUser(id) {
    UserDataService.get(id)
      .then(response => {
        this.setState({
          currentUser: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }



  updateUser(e) {

    e.preventDefault();

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {

      UserDataService.update(
        this.state.currentUser.id, { 
          user : this.state.currentUser,
          updatePassword : this.state.newPassword ? true : false
        }
        
      )
        .then(response => {
          console.log("updated");
          console.log(response.data);
          this.getUser(this.state.currentUser.id);
          this.setState(function (prevState) {
            return {
              message: response.data.message,
              newPassword:"",
              doUpdatePassword : false
            }
            
          });
        })
        .catch(e => {
          console.log(e);
        });
      }
  }

  deleteUser() {    
    UserDataService.delete(this.state.currentUser.id)
      .then(response => {
        console.log(response.data);
        this.props.history.push('/admin/users')
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const { currentUser, isAdmin, loggedInUser, newPassword, doUpdatePassword } = this.state;

    var roles = {
      0 : "",
      1 : "User",
      2 : "Moderator",
      3 : "Admin"
    };

    return (
      <div>
        {currentUser ? (
          <div className="edit-form">
            <h4>{currentUser.username}</h4>
            <Form
              onSubmit={this.saveUser}
              ref={c => {
                this.form = c;
              }}
            >
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  id="username"
                  validations={[required, vusername]}
                  value={currentUser.username}
                  onChange={this.onChangeUsername}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="email"
                  className="form-control"
                  id="email"
                  validations={[required, email]}
                  value={currentUser.email}
                  onChange={this.onChangeEmail}
                />
              </div>
             
              {isAdmin && loggedInUser !== currentUser.id ? (
                <div className="form-group">
                  <label htmlFor="roleId">Role</label>
                  <select className="form-control"
                    id="roleId"
                    required
                    value={currentUser.roleId}
                    onChange={this.onChangeRoleId}
                    name="roleId">
                    <option value="1">User</option>
                    <option value="2">Moderator</option>
                    <option value="3">Admin</option>
                  </select>
                </div>
              ) : (
                <div className="form-group">
                  <label htmlFor="roleDisplay">Role</label>
                  <Input disabled 
                    className="form-control" 
                    id="roleDisplay" 
                    name="roleDisplay"
                    value={roles[currentUser.roleId]}
                    />
                </div>
              )
              }
              {((isAdmin || loggedInUser === currentUser.id) && !doUpdatePassword) && (
                <div className="form-group">
                  <button
                    className="badge"
                    onClick={this.onChangeDoUpdatePassword}
                  >
                    Change password
                  </button>
                </div>
              )
              }
               {((isAdmin || loggedInUser === currentUser.id) && doUpdatePassword) && (
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Input 
                    type="password"
                    className="form-control"
                    id="password"
                    validations={[vpassword]}
                    value={newPassword}
                    onChange={this.onChangePassword}
                    name="password"
                  />
                </div>
              )
              }
            
            {(isAdmin && loggedInUser !== currentUser.id) && (
            <button
              className="badge badge-danger mr-2"
              onClick={this.deleteUser}
            >
              Delete
            </button>
            )}

            <button
              type="submit"
              className="badge badge-success"
              onClick={this.updateUser}
            >
              Update
            </button>


              <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
              
            </Form>

            
             
            
            
            <p>{this.state.message}</p>
          </div>
        ) : (
          <div>
            <br />
            <p>Please click on a User...</p>
          </div>
        )}
      </div>
    );
  }
}