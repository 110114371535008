import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import AuthService from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.resetPassword = this.resetPassword.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      email: "",
      password:"",
      token:"",
      id:0,
      loading: true,
      message: "",
      success: false
    };
  }

  componentDidMount() {
    console.log(this.props.match.params);
    if (this.props.match === undefined) {
      this.setState({
        loading:false,
        message : "No valid token found."
      });
    } else {
      AuthService.checkToken(this.props.match.params.id, this.props.match.params.token).then(
        response => {
          console.log(response);
          this.setState({
            loading:false,
            message:response.message,
            token:this.props.match.params.token,
            id:this.props.match.params.id,
            success:response.success
          })
        }
      )
    }
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  resetPassword(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.resetPassword(
        this.state.email,
        this.state.token,
        this.state.id,
        this.state.password
      ).then(
        (response) => {
          console.log(response);
          this.setState({
            loading: false,
            message: response.message,
            success:response.success

          });
          
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <div className="col-md-12 login">
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />



          <Form
            onSubmit={this.resetPassword}
            ref={c => {
              this.form = c;
            }}
          >

          {this.state.message && (
              <div className="form-group">
                <div className={this.state.success ? 'alert alert-success' : 'alert alert-danger'} role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                validations={[required, vpassword]}
              />
            </div>

            

            <div className="form-group">
              <button
                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Set new password</span>
              </button>
            </div>

            
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}