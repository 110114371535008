import React, { Component } from "react";
import ReactDOM from "react-dom";

import Map from "../components/map.component";
import Flickity from "react-flickity-component";
import LocationDataService from "../services/location.service";
import OptionsDataService from "../services/options.service";



export default class Home extends Component {
  constructor(props) {
    super(props);
    this.getLocations = this.getLocations.bind(this);
    this.getOptions = this.getOptions.bind(this);

    this.state = {
      content: "",
      locations: [],
      options: {}
    };
  }

  getLocations() {
    LocationDataService.getPublished()
      .then(response => {
        var locations = response.data;

        locations.map(location => {
          if (location.lead_image) {
            var image = location.images.find(image => {
              return image.id == location.lead_image;
            });
            location.image_url = image.url;
          } else {
            location.image_url = "./img/save-public-housing.jpg";
          }
          
        });
          
        this.setState({
          locations: locations
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  getOptions() {
    OptionsDataService.get(1)
      .then(response => {
        this.setState({
          options: response.data
        });
      })
      .catch(e => {
        console.log(e);
      });

  }

  componentDidMount() {
    this.getLocations();
    this.getOptions();
  }

  render() {

    const flickityOptions = {
      wrapAround : true,
      pageDots : false
    };
    return (
      <div className="container">
      <div className="row home-intro">
      <div className="col-md-6">
        
        {this.state.options && (
          <header >
          <h1>{this.state.options.home_heading}</h1>

          <div dangerouslySetInnerHTML={{
            __html: this.state.options.home_text
          }}></div>
                    
          </header>
          )}
        
        </div>
        <div className="col-md-5 offset-md-1">
         {this.state.options.call_to_action_link && (
          <div className="call-to-action">
            {this.state.options.call_to_action_text && (
              <p>{this.state.options.call_to_action_text}</p>
            )}
            <p className="call-to-action-button">
              <a target="_blank" className="btn btn-lg btn-action" href={this.state.options.call_to_action_link}>{this.state.options.call_to_action_button || 'Take action now'}</a>
            </p>
          </div>

        )}
        {this.state.locations.length > 0 && (
        <Flickity
          className={'carousel'} // default ''
          elementType={'div'} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
          static // default false
        >
        {this.state.locations.map((location, index) => (



          <a href={process.env.REACT_APP_DOMAIN + "/locations/" + location.slug}  className="location-slide" key={location.id} >
          <div className="card">
          {location.image_url && (
          <img className="card-img-top" src={location.image_url}/>
          )}
          <h4 className="card-footer">{location.title}</h4>
          
          </div>
          </a>
        ))}

        </Flickity>

        )}
       
        
        </div>
        </div>
        <div className="map-wrap">
          <Map zoom="6" lat="-36.5" lng="145.3"/>
        </div>
      </div>
    );
  }
}