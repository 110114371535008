import React, { Component } from "react";
import LocationDataService from "../services/location.service";
import AuthService from "../services/auth.service";
import { Link } from "react-router-dom";
import Toggle from 'react-toggle'; 
import "react-toggle/style.css";

export default class LocationsList extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.onToggleOwnFilter = this.onToggleOwnFilter.bind(this);
    this.retrieveLocations = this.retrieveLocations.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveLocation = this.setActiveLocation.bind(this);
    this.removeAllLocations = this.removeAllLocations.bind(this);
    this.searchTitle = this.searchTitle.bind(this);

    this.state = {
      locations: [],
      currentLocation: null,
      currentIndex: -1,
      searchTitle: "",
      loggedInUser: null,
      isLoggedIn:false,
      isMod: false,
      isAdmin: false,
      hideOtherAuthors:false
    };
  }

  componentDidMount() {
    const loggedInUser = AuthService.getCurrentUser();

    if (loggedInUser) {
      this.setState({
        loggedInUser: loggedInUser.id,
        isLoggedIn:true,
        isMod: loggedInUser.roles.includes("ROLE_MODERATOR"),
        isAdmin: loggedInUser.roles.includes("ROLE_ADMIN"),
      });
      console.log(loggedInUser);
    }
    this.retrieveLocations();
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle
    });
  }

  onToggleOwnFilter(e) {
    const value = e.target.value;
    this.setState({
      hideOtherAuthors:!this.state.hideOtherAuthors
    });
    this.setState({
      currentLocation: null,
      currentIndex: -1
    });

    if (!this.state.hideOtherAuthors) {
      LocationDataService.findByAuthor(this.state.loggedInUser)
      .then(response => {
        this.setState({
          locations: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
    } else {
      this.retrieveLocations();
    }

    
  }

  retrieveLocations() {
    LocationDataService.getAll()
      .then(response => {
        this.setState({
          locations: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveLocations();
    this.setState({
      currentLocation: null,
      currentIndex: -1
    });
  }

  setActiveLocation(location, index) {
    this.setState({
      currentLocation: location,
      currentIndex: index
    });
  }

  removeAllLocations() {
    LocationDataService.deleteAll()
      .then(response => {
        console.log(response.data);
        this.refreshList();
      })
      .catch(e => {
        console.log(e);
      });
  }

  searchTitle() {
    this.setState({
      currentLocation: null,
      currentIndex: -1
    });

    LocationDataService.findByTitle(this.state.searchTitle)
      .then(response => {
        this.setState({
          locations: response.data
        });
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  }



  render() {
    const { searchTitle, locations, currentLocation, currentIndex } = this.state;

    return (
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by title"
              value={searchTitle}
              onChange={this.onChangeSearchTitle}
            />

            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={this.searchTitle}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-8">
        <label>
            <Toggle
              defaultChecked={this.state.hideOtherAuthors}
              onChange={this.onToggleOwnFilter} />
              <br/>
            <span>Show only my locations</span>
          </label>
        </div>
        <div className="col-md-6">
          <h4>Locations List</h4>

          <ul className="list-group">
            {locations &&
              locations.map((location, index) => (
                <li
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => this.setActiveLocation(location, index)}
                  key={index}
                >
                  {location.title}
                </li>
              ))}
          </ul>

          
        </div>
        <div className="col-md-6">
          {currentLocation ? (
            <div>
              <h4>Location</h4>
              <div>
                <label>
                  <strong>Title:</strong>
                </label>{" "}
                {currentLocation.title}
              </div>
              <div>
                <label>
                  <strong>Description:</strong>
                </label>{" "}
                {currentLocation.description}
              </div>
              <div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                {currentLocation.published ? "Published" : "Pending"}
              </div>
              {(this.state.loggedInUser == currentLocation.authorId || this.state.isMod || this.state.isAdmin) && (
                <Link
                  to={"/admin/locations/" + currentLocation.id}
                  className="badge badge-warning"
                >
                  Edit
                </Link>
              )}
              
            </div>
          ) : (
            <div>
              <br />
              <p>Please click on a Location...</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}