import http from "../http-common";
import authHeader from './auth-header';

class ResourceDataService {
  getAll() {
    return http.get("/resources", { headers: authHeader() });
  }

  get(id) {
    return http.get(`/resources/${id}`, { headers: authHeader() });
  }

  create(data) {
    return http.post("/resources", data, { headers: authHeader() });
  }

  update(id, data) {
    return http.put(`/resources/${id}`, data, { headers: authHeader() });
  }

  delete(id) {
    return http.delete(`/resources/${id}`, { headers: authHeader() });
  }

  findByLocation(id) {
    return http.get(`/resources/location/${id}`, { headers: authHeader() });
  }
}

export default new ResourceDataService();