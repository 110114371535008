import http from "../http-common";
import authHeader from './auth-header';

class ImageDataService {
  getAll() {
    return http.get("/images", { headers: authHeader() });
  }

  get(id) {
    return http.get(`/images/${id}`, { headers: authHeader() });
  }

  create(data) {
    return http.post("/images", data, { headers: authHeader() });
  }

  update(id, data) {
    return http.put(`/images/${id}`, data, { headers: authHeader() });
  }

  delete(id) {
    return http.delete(`/images/${id}`, { headers: authHeader() });
  }

  findByLocation(id) {
    return http.get(`/images/location/${id}`, { headers: authHeader() });
  }
}

export default new ImageDataService();