import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import UserDataService from "../services/user.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = value => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = value => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.newUser = this.newUser.bind(this);

    this.state = {
      id: null,
      username: "",
      email: "", 
      role:1,
      password:"",
      success: false,
      message:""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  onChangeRole(e) {
    this.setState({
      role: e.target.value
    });
  }

  saveUser(e) {
    e.preventDefault();

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      var data = {
        user : {
          username: this.state.username,
          email: this.state.email,
          password: this.state.password,
          role:this.state.role
        }
        
      };

      UserDataService.create(data)
        .then(response => {
          console.log(response);
          if (response.data.success) {
            this.setState({
              id: response.data.user.id,
              username: response.data.user.username,
              email: response.data.user.email,
              role: response.data.user.role,

              success: response.data.success,
              message:response.data.message
            });
          } else {
            this.setState({

              success: response.data.success,
              message:response.data.message
            });
          }
          
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
      }
  }

  newUser() {
    this.setState({
      id: null,
      email: "",
      username: "",
      password:"",
      role: 1,
      message:"",
      success: false
    });
  }

  render() {
    return (
      <div className="submit-form">
        {this.state.success ? (
          <div>
            <h4>{this.state.message}</h4>
            <a className="btn" href={'/admin/users/' + this.state.id}>
              View user
            </a>
            <button className="btn" onClick={this.newUser}>
              Add another user
            </button>
          </div>
        ) : (
          <div>
            <h4>Add new user</h4>
            <Form
            onSubmit={this.saveUser}
              ref={c => {
                this.form = c;
              }}
            >
            <div className="form-group">
              <label htmlFor="username">Username</label>
              <Input
                type="text"
                className="form-control"
                id="username"
                validations={[required, vusername]}
                value={this.state.username}
                onChange={this.onChangeUsername}
                name="username"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Input
                type="email"
                className="form-control"
                id="email"
                validations={[required, email]}
                value={this.state.email}
                onChange={this.onChangeEmail}
                name="email"
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                id="password"
                validations={[required, vpassword]}
                value={this.state.password}
                onChange={this.onChangePassword}
                name="password"
              />
            </div>

            <div className="form-group">
              <label htmlFor="role">Role</label>
              <select className="form-control"
                id="role"
                required
                value={this.state.role}
                onChange={this.onChangeRole}
                name="role">
                <option value="1">User</option>
                <option value="2">Moderator</option>
                <option value="3">Admin</option>
              </select>
              
            </div>

           

            <button className="btn btn-success">
              Submit
            </button>
            <p>{this.state.message}</p>

            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
            </Form>
          </div>

        )}
      </div>
    );
  }
}