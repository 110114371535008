import axios from "axios";

const API_URL = process.env.REACT_APP_AUTH_URL;

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "signin", {
        email,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }

  requestToken(email) {
    return axios
      .post(API_URL + "request-token", {
        email
      })
      .then(response => {
        return response.data;
      });
  }

  checkToken(id,token) {
    return axios
      .post(API_URL + "check-token", {
        id,
        token
      })
      .then(response => {
        return response.data;
      });
  }
  resetPassword(email, token, id,password) {
    return axios
      .post(API_URL + "reset-password", {
        email,
        token,
        id,
        password
      })
      .then(response => {
        return response.data;
      });
  }
}

export default new AuthService();