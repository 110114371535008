import http from "../http-common";
import authHeader from './auth-header';

class LocationDataService {
  getAll() {
    return http.get("/locations", { headers: authHeader() });
  }

  getPublished() {
    return http.get("/locations/published", { headers: authHeader() });
  }

  getSinglePublished(id) {
    return http.get(`/locations/published/${id}`, { headers: authHeader() });
  }

  get(id) {
    return http.get(`/locations/${id}`, { headers: authHeader() });
  }

  create(data) {
    return http.post("/locations", data, { headers: authHeader() });
  }

  update(id, data) {
    return http.put(`/locations/${id}`, data, { headers: authHeader() });
  }

  delete(id) {
    return http.delete(`/locations/${id}`, { headers: authHeader() });
  }

  deleteAll() {
    return http.delete(`/locations`, { headers: authHeader() });
  }

  findByTitle(title) {
    return http.get(`/locations?title=${title}`, { headers: authHeader() });
  }

  findBySlug(slug) {
    return http.get(`/locations/slug/${slug}`, { headers: authHeader() });
  }

  findByAuthor(id) {
    return http.get(`/locations/author/${id}`, { headers: authHeader() });
  }
}

export default new LocationDataService();