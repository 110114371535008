import React, { Component } from "react";
import CategoryDataService from "../services/category.service";
import axios from 'axios';


export default class CategoryList extends Component {
  constructor(props) {
  	super(props);
  	this.getCategories = this.getCategories.bind(this);
  	this.onChangeName = this.onChangeName.bind(this);
    this.onChangeColor = this.onChangeColor.bind(this);
  	this.onRemove = this.onRemove.bind(this);
  	this.onUpdate = this.onUpdate.bind(this);
  	this.onChangeNewName = this.onChangeNewName.bind(this);
    this.onChangeNewColor = this.onChangeNewColor.bind(this);
  	this.onCreate = this.onCreate.bind(this);
  	this.state = {
  		categories : [],
  		newCategory : {
  			name : '',
        color : ''
  		}
  	}
  }

  componentDidMount() {
  	this.getCategories();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  	if(this.props.location !== prevProps.location) {
  		this.getResidents(this.props.location);
  	}
  	
  }

  getCategories(cb) {
		CategoryDataService.getAll()
		  .then(response => {
        console.log(response.data);
        this.setState({
          categories: response.data
        });
        
        
        typeof cb === 'function' && cb();
      })
      .catch(e => {
        console.log(e);
      });

  	
  }

  onChangeName(id, e) {
  	
	const newCategories = this.state.categories.map((category) => {
      if (category.id === id) {
        const updatedCategory = {
          ...category,
          name: e.target.value,
        };
 
        return updatedCategory;
      }
 
      return category;
    });
	this.setState({
		categories : newCategories
	});
  }

  onChangeColor(id, e) {
    
  const newCategories = this.state.categories.map((category) => {
      if (category.id === id) {
        const updatedCategory = {
          ...category,
          color: e.target.value,
        };
 
        return updatedCategory;
      }
 
      return category;
    });
  this.setState({
    categories : newCategories
  });
  }

  

  onRemove(id) {
  	if (window.confirm("Are you sure you want to delete this category?")) {
  		CategoryDataService.delete(id)
	  	.then(response => {
	  		this.getCategories();
	  	})
	  	.catch(e => {
	  		console.log(e);
	  	});
  	}
  	
  }

  onUpdate(e,id) {
    e.preventDefault();
  	const category = this.state.categories.find(category => {
	  return category.id === id
	});
  	
  	CategoryDataService.update(id, category)
	  	.then(response => {
	  		this.getCategories( () => {
            const newCategories = this.state.categories.map((category) => {
                if (category.id === id) {
                  const updatedCategory = {
                    ...category,
                    status:"Updated successfully",
                  };
           
                  return updatedCategory;
                }
           
                return category;
              });
            newCategories.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1);
            this.setState({
              categories : newCategories
            });
            console.log('state');
            console.log(this.state.categories);
        });
	  	})
	  	.catch(e => {
	  		console.log(e);
        const newCategories = this.state.categories.map((category) => {
            if (category.id === id) {
              const updatedCategory = {
                ...category,
                status:"Update unsuccessful",
              };
       
              return updatedCategory;
            }
       
            return category;
          });

        this.setState({
          categories : newCategories
        });
	  	});

  }

  onChangeNewName(e) {
  	this.setState(prevState => ({
       newCategory: {
        ...prevState.newCategory,
        name: e.target.value
      }
    }));
  }

  onChangeNewColor(e) {
    this.setState(prevState => ({
       newCategory: {
        ...prevState.newCategory,
        color: e.target.value
      }
    }));
  }




  onCreate() {

  	var data = {
  		'name' : this.state.newCategory.name,
      'color' : this.state.newCategory.color
  	};

  	CategoryDataService.create(data)
      .then(response => {
        this.setState({
          newCategory : {
  			   name : '',
           color : ''
  		  }
        });
        this.getCategories();
      })
      .catch(e => {
        console.log(e);
      });

  }


  render () {
  	return (
  		<div className="location-library">
      <h2>Categories</h2>
  		<ul>
		      {this.state.categories && this.state.categories.length > 0 && this.state.categories.map(category => (
		      	<li  key={category.id}>
            <div className="card">
            <div className="card-body">
			      	<label htmlFor={"name-" + category.id}>Name</label>
			    	<input
				    	type="text"
		                className="form-control"
		                id={"name-" + category.id}
		                value={category.name || ''}
		                onChange={(e) => this.onChangeName(category.id,e)}
		                />
		            
                    
                    <label htmlFor={"color-" + category.id}>Colour</label>
                    <p className="small">Hexcode, e.g. #ff0000</p>
                    <input
              type="text"
                    className="form-control"
                    id={"color-" + category.id}
                    value={category.color || ''}
                    onChange={(e) => this.onChangeColor(category.id,e)}
                    />
                
                    </div>
                    <div className="card-footer">

		            <button 
              type="button" 
              onClick={(e) => this.onUpdate(e,category.id)}
              className="btn btn-success"
              >
                Update
              </button>
              <button 
              type="button" 
              onClick={() => this.onRemove(category.id)}
              className="btn btn-danger"
              >
                Remove
              </button>
              <div className="status">
                {category.status}
              </div>
              </div>
              </div>
		      	</li>
		      ))}
		    </ul>
		    <div className="card">
			    
          <div className="card-body">
          <h4 className="card-title">Add new category</h4>
			    <label htmlFor="new-name">Name</label>
			    	
			    	<input
				    	type="text"
		                  className="form-control"
		                  id="new-name"
		                  value={this.state.newCategory.name}
		                  onChange={this.onChangeNewName}
                      required
		                  />

                      <label htmlFor="new-color">Colour</label>
            
            <input
              type="text"
                      className="form-control"
                      id="new-color"
                      value={this.state.newCategory.color}
                      onChange={this.onChangeNewColor}
                      required
                      />


	                  <button
	                  	type="button"
	                  	value="Upload"
	                  	onClick={this.onCreate}
                      className="btn btn-primary"
	                  	>Add category</button>

            </div>
		    </div>
  		</div>
	)
  }
}