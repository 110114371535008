import React, { Component } from "react";
import ImageDataService from "../services/image.service";
import axios from 'axios';

export default class ImageLibrary extends Component {
  constructor(props) {
  	super(props);
  	this.onRemove = this.onRemove.bind(this);
  	this.onUpdate = this.onUpdate.bind(this);
  	this.onUpload = this.onUpload.bind(this);
  	this.onChangeCaption = this.onChangeCaption.bind(this);
  	this.onChangeCopyright = this.onChangeCopyright.bind(this);
  	this.onChangeNewCaption = this.onChangeNewCaption.bind(this);
  	this.onChangeNewCopyright = this.onChangeNewCopyright.bind(this);
  	this.onChangeFile = this.onChangeFile.bind(this);
  	this.getImages = this.getImages.bind(this);
  	this.state = {
  		images : [],
  		newImage : {
  			copyright : '',
  			caption : '',
  			file : null
  		}
  	}
  }

  componentDidMount() {
  	this.getImages(this.props.location);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  	if(this.props.location !== prevProps.location) {
  		this.getImages(this.props.location);
  	}
  	
  }

  getImages(location, cb) {

  	if (location) {
  		ImageDataService.findByLocation(location)
  		  .then(response => {
	        this.setState({
	          images: response.data
	        });
	        
	        console.log(response.data);
          typeof cb === 'function' && cb();
	      })
	      .catch(e => {
	        console.log(e);
	      });

  	}
  }

  onChangeNewCopyright(e) {
  	this.setState(prevState => ({
       newImage: {
        ...prevState.newImage,
        copyright: e.target.value
      }
    }));
  }

  onChangeNewCaption(e) {
  	this.setState(prevState => ({
       newImage: {
        ...prevState.newImage,
        caption: e.target.value
      }
    }));
  }

  onChangeFile(e) {
  	this.setState(prevState => ({
       newImage: {
        ...prevState.newImage,
        file: e.target.files[0]
      }
    }));
  }

  onChangeCaption(id, e) {
  	
	const newImages = this.state.images.map((image) => {
      if (image.id === id) {
        const updatedImage = {
          ...image,
          caption: e.target.value,
        };
 
        return updatedImage;
      }
 
      return image;
    });
	this.setState({
		images : newImages
	});
  }

  onChangeCopyright(id,e) {

  	const newImages = this.state.images.map((image) => {
      if (image.id === id) {
        const updatedImage = {
          ...image,
          copyright: e.target.value,
        };
 
        return updatedImage;
      }
 
      return image;
    });
	this.setState({
		images : newImages
	});

  }

  onUpload() {

  	// check if file available

  	if (this.state.newImage.file === null) {
  		alert('Please select a file to upload.');
  		return;
  	}

  	const formData = new FormData();
  	formData.append('caption' , this.state.newImage.caption);
  	formData.append('copyright' , this.state.newImage.copyright);
  	formData.append('locationId' , this.props.location);
  	formData.append('file' , this.state.newImage.file);
  	formData.append('name' , this.state.newImage.file.name);


  	ImageDataService.create(formData)
      .then(response => {
        this.setState({
          newImage : {
  			copyright : '',
  			caption : '',
  			file:null
  		  }
        });
        document.getElementById("new-image-file").value = "";
        this.getImages(this.props.location);
      })
      .catch(e => {
        console.log(e);
      });

  }

  onRemove(id) {
  	if (window.confirm("Are you sure you want to delete this image?")) {
  		ImageDataService.delete(id)
	  	.then(response => {
	  		this.getImages(this.props.location);
	  	})
	  	.catch(e => {
	  		console.log(e);
	  	});
  	}
  	
  }

  onUpdate(id) {
  	var image = this.state.images.find(image => {
  	  return image.id === id
  	});
  	
  	ImageDataService.update(id, image)
	  	.then(response => {
	  		this.getImages(this.props.location, () => {
            const newImages = this.state.images.map((image) => {
                if (image.id === id) {
                  const updatedImage = {
                    ...image,
                    status:"Updated successfully",
                  };
           
                  return updatedImage;
                }
           
                return image;
              });
            this.setState({
              images : newImages
            });
        });
        
	  	})
	  	.catch(e => {
	  		console.log(e);
        const newImages = this.state.images.map((image) => {
                if (image.id === id) {
                  const updatedImage = {
                    ...image,
                    status:"Unable to update image.",
                  };
           
                  return updatedImage;
                }
           
                return image;
              });
            this.setState({
              images : newImages
            });
	  	});

  }

  render () {
  	return (
	    <div className="location-library">
      <h2>Images</h2>
		    <ul>
		      {this.state.images && this.state.images.length > 0 && this.state.images.map(image => (
		        <li key={image.id}>
            <div className="card">
            <div className="card-body">

             {(this.props.lead_image == image.id) && (
                <span className="lead-image">Lead image</span>
              )}
		        	
              <img 
              src={image.url} 
              style={{
                "maxHeight":"200px",
                "maxWidth":"200px",
                "display":"block"
              }}/>

             
		        	<label htmlFor={"caption-" + image.id}>Caption</label>
			    	<input
				    	type="text"
		                  className="form-control"
		                  id={"caption-" + image.id}
		                  value={image.caption}
		                  onChange={(e) => this.onChangeCaption(image.id,e)}
		                  />
		                  <label htmlFor={"copyright-" + image.id}>Copyright</label>
			    	<input
				    	type="text"
		                  className="form-control"
		                  id={"copyright-" + image.id}
		                  value={image.copyright}
		                  onChange={(e) => this.onChangeCopyright(image.id,e)}
		                  />
		          
              </div>
              <div className="card-footer">
		          <button 
              type="button" 
              onClick={() => this.onUpdate(image.id)}
              className="btn btn-success"
              >
                Update
              </button>
              <button 
              type="button" 
              onClick={() => this.onRemove(image.id)}
              className="btn btn-danger"
              >
                Remove
              </button>
		          {(this.props.lead_image !== image.id) && (
		          <button 
              type="button" 
              onClick={() => this.props.onSetLeadImage(image.id)}
              className="btn btn-primary"
              >
		            Set as lead image
		          </button>
		          )}
              <div className="status">
                {image.status}
              </div>
              </div>
              </div>
		        </li>
		      ))}
		    </ul>
		    <div className="card">
		    
        <div className="card-body">
          <h4 className="card-title">Add new image</h4>
  		    <label htmlFor="new-image-file">Image</label>
  		    <input 
  		    type="file" 
  		    className="form-control"
  		    id="new-image-file" 
  		    onChange={this.onChangeFile}
  		     />
  		    <label htmlFor="new-caption">Caption</label>
  		    	
  		    	<input
  			    	type="text"
  	                  className="form-control"
  	                  id="new-caption"
  	                  value={this.state.newImage.caption}
  	                  onChange={this.onChangeNewCaption}
  	                  />

  	                  <label htmlFor="new-copyright">Copyright</label>
  		    	<input
  		    	type="text"
                    className="form-control"
                    id="new-copyright"
                    value={this.state.newImage.copyright}
                    onChange={this.onChangeNewCopyright}
                    />

                    <button
                    	type="button"
                    	value="Upload"
                    	onClick={this.onUpload}
                      className="btn btn-primary"
                    	>Upload</button>
                </div>
                  
		    </div>
	    </div>
	  )
  }
  
}