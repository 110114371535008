import React, { Component } from "react";
import LocationDataService from "../services/location.service";
import AuthService from "../services/auth.service";


export default class AddLocation extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.saveLocation = this.saveLocation.bind(this);
    this.newLocation = this.newLocation.bind(this);

    this.state = {
      id: null,
      title: "",
      description: "", 
      published: false,
      submitted: false,
      loggedInUser: null,
      isLoggedIn:false,
      isMod: false,
      isAdmin: false,
    };
  }

  componentDidMount() {
    const loggedInUser = AuthService.getCurrentUser();

    if (loggedInUser) {
      this.setState({
        loggedInUser: loggedInUser.id,
        isLoggedIn:true,
        isMod: loggedInUser.roles.includes("ROLE_MODERATOR"),
        isAdmin: loggedInUser.roles.includes("ROLE_ADMIN"),
      });
    }
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value
    });
  }

  saveLocation() {
    var data = {
      title: this.state.title,
      description: this.state.description,
      authorId:this.state.loggedInUser
    };

    LocationDataService.create(data)
      .then(response => {
        this.setState({
          id: response.data.id,
          title: response.data.title,
          description: response.data.description,
          published: response.data.published,
          submitted: true
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  newLocation() {
    this.setState({
      id: null,
      title: "",
      description: "",
      published: false,
      submitted: false
    });
  }

  render() {
    return (
      <div className="submit-form">
        {this.state.submitted ? (
          <div>
            <h4>You submitted successfully!</h4>
            <a className="btn" href={'/admin/locations/' + this.state.id}>
              Edit location
            </a>
            <button className="btn btn-success" onClick={this.newLocation}>
              Add another location
            </button>
          </div>
        ) : (
          <div>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                className="form-control"
                id="title"
                required
                value={this.state.title}
                onChange={this.onChangeTitle}
                name="title"
              />
            </div>

            <div className="form-group">
              <label htmlFor="description">Description</label>
              <p className="small">Describe the location in one sentence. This will be shown on the map pop ups.</p>
              <textarea
                type="text"
                className="form-control"
                id="description"
                required
                value={this.state.description}
                onChange={this.onChangeDescription}
                name="description"
              >
              </textarea>
            </div>

            

            <button onClick={this.saveLocation} className="btn btn-success">
              Submit
            </button>
          </div>
        )}
      </div>
    );
  }
}