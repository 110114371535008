import React, { Component } from "react";
import OptionsDataService from "../services/options.service";
import axios from 'axios';
import TiptapSimple from '../components/tiptap-simple.component';



export default class OptionsEditor extends Component {
  constructor(props) {
  	super(props);
  	this.getOptions = this.getOptions.bind(this);
  	this.onChangeTextField = this.onChangeTextField.bind(this);
    this.onChangeTiptap = this.onChangeTiptap.bind(this);
  	this.onUpdate = this.onUpdate.bind(this);
  	this.state = {
      options : {
        home_heading : '',
        home_text :  '',
        call_to_action_text :  '',
        call_to_action_button :  '',
        call_to_action_link :  '',
        footer_text_1 : '',
        footer_text_2 : ''
      },
      message  : '',
      messageClass  : ''
  	}
  }

  componentDidMount() {
  	this.getOptions();
  }


  getOptions(cb) {
		OptionsDataService.get(1)
		  .then(response => {
        this.setState({
          options : response.data
        });
        
        
        typeof cb === 'function' && cb();
      })
      .catch(e => {
        console.log(e);
      });

  	
  }



  onUpdate(e) {
    e.preventDefault();
    this.setState({
      messageClass  : 'loading'
    });

  	OptionsDataService.update(1, this.state.options)
	  	.then(response => 
        this.setState({
	  		   message : "Options updated successfully",
           messageClass : 'success'
	  	  })
      )
	  	.catch(e => {
	  		console.log(e);
        this.setState({
          message : "Unable to update options.",
           messageClass : 'danger'
        });
	  	});

  }

  onChangeTextField(e, field) {
  	this.setState(prevState => {
      var newOptions = prevState.options;
      newOptions[field] = e.target.value;

      return newOptions;
    });
  }

  onChangeTiptap(tiptap, field) {
    this.setState(prevState => {
      var newOptions = prevState.options;
      newOptions[field] = tiptap;

      return newOptions;
    });
    
  }




  render () {
  	return (
  		<div>
      <h2>Options</h2>

		    <div className="card">
			    
          <div className="card-body">
  			    <label htmlFor="home_heading">Home heading</label>
  			    	
  		    	<input
  			    	type="text"
              className="form-control"
              id="home_heading"
              value={this.state.options.home_heading || ''}
              onChange={(e) => {this.onChangeTextField(e,"home_heading")}}
              required
              />

            <label htmlFor="home_text">Home text</label>
            
            <TiptapSimple
              data-lpignore="true"
              id="home_text"
              content={this.state.options.home_text || ''} 
              onChange={(e) => {this.onChangeTiptap(e,"home_text")}} 
              />

            <label htmlFor="call_to_action_text">Call to action text</label>
            <textarea
              className="form-control"
              id="call_to_action_text"
              value={this.state.options.call_to_action_text || ''}
              onChange={(e) => {this.onChangeTextField(e,"call_to_action_text")}}
              required
              />

            <label htmlFor="call_to_action_link">Call to action link</label>
            <input
              type="url"
              className="form-control"
              id="call_to_action_link"
              value={this.state.options.call_to_action_link || ''}
              onChange={(e) => {this.onChangeTextField(e,"call_to_action_link")}}
              required
              />

            <label htmlFor="call_to_action_button">Call to action button text</label>
            <input
              type="text"
              className="form-control"
              id="call_to_action_button"
              value={this.state.options.call_to_action_button || ''}
              onChange={(e) => {this.onChangeTextField(e,"call_to_action_button")}}
              required
              />

            <label htmlFor="footer_text_1">Footer text 1</label>
            
            <TiptapSimple
              data-lpignore="true"
              id="footer_text_1"
              content={this.state.options.footer_text_1 || ''} 
              onChange={(e) => {this.onChangeTiptap(e,"footer_text_1")}} 
              />

            <label htmlFor="footer_text_2">Footer text 2</label>
            
            <TiptapSimple
              data-lpignore="true"
              id="footer_text_2"
              content={this.state.options.footer_text_2 || ''} 
              onChange={(e) => {this.onChangeTiptap(e,"footer_text_2")}} 
              />
              <br/>
                     
            <button
            	type="button"
            	value="Upload"
            	onClick={this.onUpdate}
              className="btn btn-primary"
            	>Update</button>
<br/><br/>
               <p className={'alert alert-' + this.state.messageClass}>{this.state.message}</p>
                  {this.state.messageClass=='loading' && (
                    <div className="spinner-border text-secondary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}

            </div>

           
		    </div>
  		</div>
	)
  }
}